import React from "react";
import topbar from "../assets/Windroos-topbar.png";

function Topbar() {
	return (
		<div>
			<img src={topbar} alt="Windroos" className="img-header" />
		</div>
	);
}

export default Topbar;
