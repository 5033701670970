import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function InformatieDocumenten() {
	function createData(name, category, link) {
		return { name, category, link };
	}

	const rows = [
		createData(
			"Schoolgids",
			"School & Beleid",
			"https://wrassets.boss-tech.nl/documents/beleid/Venster schoolgids 2023-2024 69447-07CL-000 23-04-2024.pdf"
		),
		createData(
			"Schoolplan",
			"School & Beleid",
			"https://wrassets.boss-tech.nl/documents/beleid/Windroos schoolplan 2018-2022.pdf"
		),
		createData(
			"School ondersteunings profiel",
			"School & Beleid",
			"https://wrassets.boss-tech.nl/documents/beleid/SOP Schoolondersteuningsprofiel 2023 2024.pdf"
		),

		createData(
			"Voedingsbeleid",
			"School & Beleid",
			"https://wrassets.boss-tech.nl/documents/beleid/voedingsbeleidDeWindroos.pdf"
		),
		createData(
			"Beleidsplan sociale veiligheid",
			"School & Beleid",
			"https://wrassets.boss-tech.nl/documents/beleid/Beleidsplan sociale veiligheid 2023 2024.pdf"
		),
	];

	return (
		<div style={{ maxWidth: "720px", marginLeft: "10%" }}>
			<Typography variant="h5">Documenten</Typography>
			<Typography variant="body1" sx={{ marginBottom: 5 }}>
				Op deze pagina vind je nog één keer al onze belangrijke documenten die
				op deze website te vinden zijn op een rij, per categorie.
			</Typography>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell sx={{ fontFamily: "windroos, sans serif" }}>
								<strong>Bestandsnaam</strong>
							</TableCell>
							<TableCell
								sx={{ fontFamily: "windroos, sans serif" }}
								align="left"
							>
								<strong>Categorie</strong>
							</TableCell>
							<TableCell
								sx={{ fontFamily: "windroos, sans serif" }}
								align="left"
							>
								<strong>Link</strong>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow
								key={row.name}
								sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
							>
								<TableCell component="th" scope="row">
									{row.name}
								</TableCell>
								<TableCell align="left">{row.category}</TableCell>
								<TableCell align="left">
									<a
										href={row.link}
										target="_blank"
										rel="noopener noreferrer"
										download
									>
										link
									</a>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default InformatieDocumenten;
