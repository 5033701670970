import React from "react";
import WindroosTheme from "./theme/WindroosTheme";
import { Routes, Route } from "react-router-dom";
import { Amplify } from "aws-amplify";
// highlight-next-line
import awsconfig from "./aws-exports";
//components
import Topbar from "./components/Topbar";
import Menubar from "./components/Menubar";
import Footer from "./components/Footer";
import AddCalenderItem from "./components/AddCalendarItem";

//pages
import Introduction from "./pages/Introduction";
import Homepage from "./pages/Homepage";
import Ouders from "./pages/Ouders";
import Visie from "./pages/Visie";
import NotFound from "./pages/404";
import Kalender from "./pages/Calendar";
import Freinet from "./pages/Freinet";
import Contact from "./pages/Contact";
import Klas from "./pages/Klas";
import Meerover from "./pages/Meerover";
import InformatieDocumenten from "./pages/InformatieDocumenten";
import Groepen from "./pages/Groepen";
import NieuwsSingle from "./pages/NieuwsSingle";
import Siteinfo from "./pages/Siteinfo";
//development
import Version from "./components/Version";
import Vacatures from "./pages/Vacatures";

//material-ui
import { ThemeProvider } from "@mui/material";
import Theme from "./theme/WindroosTheme";
import Aanmelden from "./pages/Aanmelden";

const theme = Theme;
Amplify.configure(awsconfig);

function App() {
	return (
		<div>
			<ThemeProvider theme={WindroosTheme}>
				<Topbar />
				<Menubar theme={WindroosTheme} />
				<Routes>
					<Route path="/" element={<Introduction />} />
					<Route path="/homepage" element={<Homepage theme={theme} />} />
					<Route path="/ouders" element={<Ouders theme={theme} />} />
					<Route path="/visie" element={<Visie theme={theme} />} />
					<Route path="*" element={<NotFound theme={theme} />} />
					<Route path="/kalender" element={<Kalender theme={theme} />} />
					<Route path="/freinet" element={<Freinet theme={theme} />} />
					<Route path="/contact" element={<Contact theme={theme} />} />
					<Route path="/klas" element={<Klas theme={theme} />} />
					<Route path="/onzeschool" element={<Meerover theme={theme} />} />
					<Route path="/groepen" element={<Groepen theme={theme} />} />
					<Route path="/nieuws" element={<NieuwsSingle theme={theme} />} />
					<Route path="/siteinfo" element={<Siteinfo theme={theme} />} />
					<Route path="/vacatures" element={<Vacatures theme={theme} />} />
					<Route path="/aanmelden" element={<Aanmelden theme={theme} />} />
					<Route
						path="/documenten"
						element={<InformatieDocumenten theme={theme} />}
					/>
					<Route
						path="/addcalendar"
						element={<AddCalenderItem theme={theme} />}
					/>
					<Route path="/development" element={<Version theme={theme} />} />
				</Routes>

				<Footer theme={WindroosTheme} />
			</ThemeProvider>
		</div>
	);
}

export default App;
